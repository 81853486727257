import React, { useState } from "react";
import { MdEmail } from "react-icons/md";
import { IoMdLock } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";

import {
  InfoContainer,
  Column1,
  TextWrapper,
  LoginBox,
  LoginHeading,
  LoginPara,
  InputBox,
  LoginButton,
  LoginBtnWrapper,
  LogoMenzil,
} from "./LoginElements";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { withRouter } from "react-router";
import { Formik, Field, Form } from "formik";
import InputLogin from "../InputLogin";
import { Link, useHistory } from "react-router-dom";
import Overlay from "../Overlay";
import axios from "../../axios";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import logoAboveLogin from "../../assets/images/logo-white.png";
import dotImage from "../../assets/images/dot-img.png";
import "./locationdropdown.css";
import { loginValidator } from "../../utils/validators";
import Input from "../../components/Input";
import logocoin from "../../images/hewe/icon.png";
import "./style.scss";
const useStyles = makeStyles((theme) => ({
  formStyle: {
    width: "100%",
    padding: "2rem",
    overflow: "scroll",
  },
  "@media (max-width: 780px)": {
    formStyle: {
      padding: "1.8rem",
    },
    formStyleOnly: {
      padding: "1.8rem",
    },
  },
  "@media (max-width: 480px)": {
    formStyle: {
      padding: "1.3rem",
    },
    formStyleOnly: {
      padding: "1.3rem",
    },
  },

  formStyleOnly: {
    width: "100%",
  },
}));

const InfoSection = ({ setUsers, userData }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginValues, setLoginValues] = useState({
    email: "",
    password: "",
  });

  const deviceId = uuidv4();
  const deviceName = `${navigator.platform} - ${navigator.userAgent}`;

  const handleLogin = async (values) => {
    setIsLoading(true);

    var formvalues = {
      email: values.email,
      password: values.password,
      deviceId: deviceId,
      deviceName: deviceName,
      deviceType: "1",
    };

    try {
      const { data } = await axios.post("/login", formvalues);


      if (rememberMe) {
      localStorage.setItem("email", data?.data?.email);
      localStorage.setItem("token", data?.accessToken);
      localStorage.setItem("userData", JSON.stringify(data?.data));
      localStorage.setItem("deviceId", deviceId);
      } else {
        sessionStorage.setItem("email", data?.data?.email);
        sessionStorage.setItem("token", data?.accessToken);
        sessionStorage.setItem("userData", JSON.stringify(data?.data));
        sessionStorage.setItem("deviceId", deviceId);
      }

      Cookies.remove("showProfileSidebar");
      setUsers(data.data);
      console.log(data?.data?.data)
      const firstAccessibleModule = data?.data?.data?.modules?.find(module => module.access === true)?.moduleName;
      // console.log("name is ==>>>",firstAccessibleModule)
      const SidebarData = [
        {
          title: "Dashboard",
          path: "/dashboard",
        },
        {
          title: "Account Management",
          path: "/accountManagement",
        },
        {
          title: "Reward Management",
          path: "/refer-and-earn",
        },
        {
          title: "Level Management",
          path: "/level-management",
        },
        {
          title: "Daily Achievement",
          path: "/daily-achievement",
        },
        {
          title: "SubAdmin Management",
          path: "/sub-admin-management",
        },
        {
          title: "CMS Management",
          path: "/privacy-policy",
        },
        {
          title: "Notification Management",
          path: "/notification-management",
        },
      ];

      const url=SidebarData?.find(item=>item?.title == firstAccessibleModule)?.path;
      if(data?.data?.userType=="subAdmin"){
        history.replace(url);
      } else{
        history.replace("/dashboard");
      }
     
    } catch (error) {
      if (error.response.status === 422) {
        toast.error(`${error.response.data?.errors[0].msg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <section className="auth">
          <div className="login d-flex align-items-center justify-content-end ">
            <div className="logincontainer text-center">
              <div>
                <img src={logocoin} alt="logo" className="img-fluid" />
              </div>
              <div>
                <div className="heading">
                  hewe<span>club</span>
                </div>
              </div>
              <div className="loginheading d-flex align-items-center">
                <div></div>
                <h4>Admin Login</h4>
                <div></div>
              </div>
              <div className="form">
                <Formik
                  enableReinitialize
                  initialValues={loginValues}
                  validate={loginValidator}
                  validateOnChange
                  onSubmit={(values) => handleLogin(values, false)}
                >
                  {(formikBag) => {
                    return (
                      <Form className={classes.formStyleOnly}>
                        <Field name="email">
                          {({ field }) => (
                            <div className="py-2">
                              <label htmlFor="email">email</label>
                              <div className="form-controlcont">
                                <InputLogin
                                  {...field}
                                  type="email"
                                  icon={
                                    <MdEmail
                                      style={{
                                        fontSize: "24px",
                                        color: "#88AE40",
                                      }}
                                    />
                                  }
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "email",
                                      e.target.value
                                    );
                                  }}
                                  className="form-control"
                                  placeholder="Email"
                                />
                              </div>
                              <p className="error">
                                {formikBag.touched.email &&
                                formikBag.errors.email
                                  ? formikBag.errors.email
                                  : null}
                              </p>
                            </div>
                          )}
                        </Field>
                        <Field name="password">
                          {({ field }) => (
                            <div className="py-2">
                              <label htmlFor="email">Password</label>
                              <div className="form-controlcont position-relative justify-content-between">
                                <InputLogin
                                  {...field}
                                  type="password"
                                  icon={
                                    <IoMdLock
                                      style={{
                                        fontSize: "24px",
                                        color: "#88AE40",
                                      }}
                                    />
                                  }
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "password",
                                      e.target.value
                                    );
                                  }}
                                  className="form-control"
                                  placeholder="Password"
                                />
                              </div>
                              <p className="error">
                                {formikBag.touched.password &&
                                formikBag.errors.password
                                  ? formikBag.errors.password
                                  : null}
                              </p>
                            </div>
                          )}
                        </Field>
                        <div className="d-flex justify-content-start align-items-center text-white mt-2" >
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            className="pe-3"
                            onChange={() => setRememberMe(!rememberMe)} 
                          />
                          <p className="ps-3 mb-0">Keep me login</p>
                        </div>
                        <button type="submit">Log In</button>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          {isLoading && <Overlay />}
        </section>
        {isLoading && <Overlay />}
      </div>
      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InfoSection));
