import React, { useEffect, useState } from 'react'
import axios from "../../axios";
import "./style.scss"
import { toast } from 'react-toastify';

function MilesReward() {
  const [formData, setFormData] = useState({
    miles:{id:"678e5288094e705e091d7ab8",value:""},
    
  });

  const fetchRewardValue = async (key) => {
    try {
      const res = await axios.get(`/findRewardValueById/${formData[key].id}`);
      setFormData((prev) => ({
        ...prev,
        [key]: { ...prev[key], value: res.data.data[0].value },
      }));
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const updateRewardValue = async (key) => {
    try {
      const res = await axios.post("/private/setRewardType", formData[key]);
      toast.success(res.data.message);
      fetchRewardValue(key); // Refresh the value after update
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRewardValue("miles");
  }, []);


  return (
    <>
      <div className='hewe-cont'>
        <div className='referralcont'>
          <div className='flex-align-center'>
            <div className="dott me-3"></div>
            <p className='main-heading'>Miles Reward</p>
          </div>
          <div className='ps-4'>
          <div className='desc my-4'>
            <p>Define the HEWE Coin rewards users will earn for completing every milestone. Customize the coins dynamically based on the distance covered to encourage participation and engagement.</p>
          </div>
          <div className="refcont">
            <h2>Per Miles Reward</h2>
            <div className='flex-align-center mt-5'>
              <h4 className='mb-0 perrefer'>per Miles = </h4>
              <div className='ms-3'>
                <label htmlFor="Total Hewe coins">Total Hewe Coins</label>
                <div>
                <input
                type="text"
                placeholder="Enter hewe coins"
                value={formData.miles.value}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    miles: { ...prev.miles, value: e.target.value },
                  }))
                }
              /> </div>
              </div>
            </div>
            <button className='mt-5' onClick={() => updateRewardValue("miles")}>Update</button>
          </div>
          </div>

       
          
        </div>
      </div>

    </>
  )
}

export default MilesReward;