import React, { useState, useEffect } from 'react'
import { MdArrowBackIosNew, MdEdit } from 'react-icons/md'
import { FaPlus } from "react-icons/fa";
import { Field, Form, Formik } from 'formik';
import Input from '../../Input';
import { UploadImage } from '../../uplaodImage';
import { TiDelete } from "react-icons/ti";
import { createInAppValidator, createPremiumValidator, createVoucherValidator } from '../../../utils/validators';
import axios from "../../../axios"
import { BsDot } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Overlay from '../../Overlay';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { RiDeleteBin6Line, RiEditFill } from 'react-icons/ri';
import DropDown from '../../dropDown';
import { FormateDate } from '../../formateDate';
export default function EditLevelPremium({rewardValue,childData,setOpenModal}) {
  let { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory()
  // todo list
  // for how to use
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingText, setEditingText] = useState("");

  console.log("all list==>", todos)
  const addTodo = (formikBag) => {
    if (newTodo.trim()) {
      const updatedTodos = [...todos, newTodo];
      setTodos(updatedTodos);
      setNewTodo("");
      formikBag.setFieldValue("description", updatedTodos);
    }
  };

  // Delete a todo
  const deleteTodo = (index, formikBag) => {
    const updatedTodos = todos.filter((_, idx) => idx !== index);
    setTodos(updatedTodos);
    formikBag.setFieldValue("description", updatedTodos);
  };

  // Save the edited todo
  const saveTodo = (formikBag) => {
    const updatedTodos = todos.map((todo, idx) =>
      idx === editingIndex ? editingText : todo
    );
    setTodos(updatedTodos);
    setEditingIndex(null);
    setEditingText("");
    formikBag.setFieldValue("description", updatedTodos);
  };

  // for terms and condition

  const [terms, setTerms] = useState([]);
  const [newTerms, setNewTerms] = useState("");
  const [editingTermsIndex, setEditingTermsIndex] = useState(null);
  const [editingTermsText, setEditingTermsText] = useState("");

  console.log("all list==>", terms)
  const addTerms = (formikBag) => {
    if (newTerms.trim()) {
      const updatedTerms = [...terms, newTerms];
      setTerms(updatedTerms);
      setNewTerms("");
      formikBag.setFieldValue("terms", updatedTerms);
    }
  };

  // Delete a terms and condition
  const deleteTerms = (index, formikBag) => {
    const updatedTerms = terms.filter((_, idx) => idx !== index);
    setTerms(updatedTerms);
    formikBag.setFieldValue("terms", updatedTerms);
  };

  // Save the edited term and condition
  const saveTerms = (formikBag) => {
    const updatedTerms = terms.map((term, idx) =>
      idx === editingTermsIndex ? editingTermsText : term
    );
    setTerms(updatedTerms);
    setEditingTermsIndex(null);
    setEditingTermsText("");
    formikBag.setFieldValue("terms", updatedTerms);
  };
  const [value, setValue] = useState({
    rewardIcon: "",
    rewardAmount: "",
    premiumRewardType: "",
    quantity: "",
    price: "",
    rewardName: "",
    rewardExpireDate: "",
    description: "",
    terms: ""
  })

  const rewardDate = FormateDate(value?.rewardExpireDate)

  const [brandImg, setBrandImg] = useState("")

  const uploadfile = async (e, formikBag) => {
    const file = e.target.files[0];

    console.log(file)

    if (file) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = async function () {
        if (this.width > 128 || this.height > 128) {
          console.error("Image dimensions should not exceed 128x128.");
          toast.error("Image dimensions should not exceed 128x128.")
          URL.revokeObjectURL(objectUrl);
          return;
        }

        const url = await UploadImage(file);
        console.log("url is ==>>", url);
        if (url) {
          formikBag.setFieldValue("rewardIcon", url);
          setBrandImg(url);
        } else {
          console.error("Failed to upload or get image URL.");
        }

        URL.revokeObjectURL(objectUrl);
      };

      img.src = objectUrl;
    } else {
      console.error("No file selected.");
    }
  };
  const getData = async () => {
    setIsLoading(true);
    try {
      setBrandImg(childData?.Reward?.rewardIcon)
      setTodos(childData?.Reward?.description)
      setTerms(childData?.Reward?.terms)
      setValue(childData?.Reward)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  const handleCreateVoucher = (value) => {
    rewardValue(value);
    setOpenModal(false);
  }
  const option = [
    {
      id: "1",
      name: "Mobile"
    },
    {
      id: "2",
      name: "Laptop"
    },
    {
      id: "3",
      name: "Electronic Accessories"
    },
    {
      id: "4",
      name: "Premium Plus MemberShip"
    },
    {
      id: "5",
      name: "HeadPhones"
    },
  ];

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const wordLimit = 200;

  const handleDescriptionChange = (e) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/);

    if (words.length > wordLimit) {
      alert("You cannot enter more than 200 words.");
      return;
    }
    setNewTodo(inputText);
  };
  const handleTermsChange = (e) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/);

    if (words.length > wordLimit) {
      alert("You cannot enter more than 200 words.");
      return;
    }
    setNewTerms(inputText);
  };
  return (
    <div className="hewe-con">
      <div className="referralcont">
        

        <Formik
          enableReinitialize
          initialValues={value}
          validate={(values) => createInAppValidator(values)}
          validateOnChange
          onSubmit={(values) => handleCreateVoucher(values)}
        >
          <Formik
            enableReinitialize
            initialValues={value}
            validate={(values) => createPremiumValidator(values)}
            validateOnChange
            onSubmit={(values) => handleCreateVoucher(values)}
          >
            {(formikBag) => {
              { console.log(formikBag) }
              return (
                <Form className='vocherform'>
                  <div className="row align-items-baseline">
                    <div className="col-xl-3 d-flex justify-content-center align-items-center flex-column">
                      <div className='flex-col-center'>
                        <label className='text-center'>Reward Icon</label>
                        <div className="imgcont">
                          <input
                            type="file"
                            id="avatar-upload"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => uploadfile(e, formikBag)}
                          />
                          {
                            brandImg != "" ?
                              <img src={`data:image/png;base64,${brandImg}`} alt="img" className='brandimg' />
                              : <div className='flex-align-center flex-column'>
                                <FaPlus className='plusIcon' onClick={() => document.getElementById("avatar-upload").click()} />
                                <label className='text-center rewardsize mt-2'>128 x 128</label>
                              </div>
                          }
                          {
                            brandImg != "" ?
                              <RiEditFill className='edit' onClick={() => document.getElementById("avatar-upload").click()} />
                              : null
                          }
                        </div>
                        <p className='error'>{
                          formikBag.touched.rewardIcon &&
                            formikBag.errors.rewardIcon
                            ? formikBag.errors.rewardIcon
                            : null
                        }</p>

                      </div>
                    </div>
                    <div className='col-xl-9 mt-3'>
                      <div className="row">
                        <div className='col-xxl-4 col-xl-6  col-sm-6 mb-4'>
                          <label htmlFor="">reward Type</label>
                          <Field name="premiumRewardType">
                            {({ field, form }) => (
                              <>
                                <DropDown
                                  field={field}
                                  form={form}
                                  option={option}
                                  placeholder="Select Reward Type"
                                />
                                <p className='error'>{
                                  formikBag.touched.premiumRewardType &&
                                    formikBag.errors.premiumRewardType
                                    ? formikBag.errors.premiumRewardType
                                    : null
                                }</p>
                              </>

                            )}
                          </Field>
                        </div>
                        <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                          <label htmlFor="" >Quantity</label>
                          <Field className="inptCont">
                            {({ field }) => (
                              <Input
                                {...field}
                                type="number"
                                min="0"
                                placeholder='Enter Quantity'
                                value={formikBag.values.quantity}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "quantity",
                                    e.target.value
                                  );
                                }}
                                error={
                                  formikBag.touched.quantity &&
                                    formikBag.errors.quantity
                                    ? formikBag.errors.quantity
                                    : null
                                }
                                className="form-control"
                              />
                            )}
                          </Field>
                          <p className='error'>{formikBag.errors.quantity}</p>
                        </div>
                        <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                          <label htmlFor="" placeholder='Select Date' >Reward Expiry Date</label>
                          <Field className="inptCont">
                            {({ field }) => (
                              <Input
                                {...field}
                                type="date"
                                min={getTodayDate()}
                                value={FormateDate(formikBag.values.rewardExpireDate)}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "rewardExpireDate",
                                    e.target.value
                                  );
                                }}
                                error={
                                  formikBag.touched.rewardExpireDate &&
                                    formikBag.errors.rewardExpireDate
                                    ? formikBag.errors.rewardExpireDate
                                    : null
                                }
                                className="form-control"
                              />
                            )}
                          </Field>
                        </div>
                        <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                          <label htmlFor="" placeholder='Enter Quantity'>Price (in $)</label>
                          <Field className="inptCont">
                            {({ field }) => (
                              <Input
                                {...field}
                                type="number"
                                min="0"
                                step="any"
                                value={formikBag.values.price}
                                placeholder="Enter Price"
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "price",
                                    e.target.value
                                  );
                                }}
                                error={
                                  formikBag.touched.price &&
                                    formikBag.errors.price
                                    ? formikBag.errors.price
                                    : null
                                }
                                className="form-control"
                              />
                            )}
                          </Field>
                          <p className='error'>{formikBag.errors.price}</p>
                        </div>
                        <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                          <label htmlFor="" placeholder='Reward Amount'>Per User Qty</label>
                          <Field className="inptCont">
                            {({ field }) => (
                              <Input
                                {...field}
                                type="Number"
                                min="0"
                                value={formikBag.values.rewardAmount}
                                placeholder="Enter Quantity"
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "rewardAmount",
                                    e.target.value
                                  );
                                }}
                                error={
                                  formikBag.touched.rewardAmount &&
                                    formikBag.errors.rewardAmount
                                    ? formikBag.errors.rewardAmount
                                    : null
                                }
                                className="form-control"
                              />
                            )}
                          </Field>
                          <p className='error'>{formikBag.errors.rewardAmount}</p>
                        </div>
                        <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                          <label htmlFor="" >Reward Name</label>
                          <Field className="inptCont">
                            {({ field }) => (
                              <Input
                                {...field}
                                type="text"
                                minlength="2"
                                maxlength="30"
                                placeholder="please enter reward message"
                                value={formikBag.values.rewardName}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "rewardName",
                                    e.target.value
                                  );
                                }}
                                error={
                                  formikBag.touched.rewardName &&
                                    formikBag.errors.rewardName
                                    ? formikBag.errors.rewardName
                                    : null
                                }
                                className="form-control"
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <label htmlFor="">How to use</label>
                      <div className="inptCont d-flex justify-content-between align-items-end">
                        <textarea

                          type="text"
                          placeholder="Type instructions here (e.g., 'Apply code at checkout to redeem.')."
                          value={newTodo}
                          onChange={handleDescriptionChange}
                          error={
                            formikBag.touched.description &&
                              formikBag.errors.description
                              ? formikBag.errors.description
                              : null
                          }
                        />
                        <div>
                          <p className="white-space-nowrap">{newTodo?.trim()?.split(/\s+/)?.filter(word => word)?.length}/200 words</p>                          <div className='d-flex justify-content-end'>
                            <div
                              onClick={newTodo.length === 0 ? null : () => addTodo(formikBag)}
                              className={`savebtn ${newTodo.length === 0 ? 'btndisabled' : 'pointer'}`}
                              style={{ pointerEvents: newTodo.length === 0 ? 'none' : 'auto' }}
                            >
                              Save
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className='error'>{
                        formikBag.errors.description
                          ? formikBag.errors.description
                          : null
                      }</p>
                      <ul className='my-5 list-style-none'>
                        {todos.map((todo, index) => (
                          <li key={index} className="inptCont">
                            {/* Show the editing input if we are editing this item */}
                            {editingIndex === index ? (
                              <div className='d-flex justify-content-between'>
                                <input
                                  type="text"
                                  value={editingText}
                                  onChange={(e) => setEditingText(e.target.value)}
                                />
                                <div onClick={() => saveTodo(formikBag)} className='savebtn'>Save</div>
                              </div>
                            ) : (
                              <div className='d-flex justify-content-between word-break-all font-italic'>
                                <span><BsDot /> {todo}</span>
                                <div className='d-flex align-items-start '>
                                  <div className='font-#F8F8F8 border-0 pointer me-2 fs-20' onClick={() => {
                                    setEditingIndex(index);
                                    setEditingText(todo);
                                  }}>
                                    <MdEdit />
                                  </div>
                                  <div className='bg-white border-0 pointer fs-20' onClick={() => deleteTodo(index, formikBag)}> <RiDeleteBin6Line /> </div>
                                </div>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-12">
                      <label htmlFor="">Terms and Condition</label>
                      <div className="inptCont d-flex justify-content-between align-items-end">
                        <textarea

                          type="text"
                          placeholder="Add a new Terms and condition"
                          value={newTerms}
                          onChange={handleTermsChange}
                          error={
                            formikBag.touched.terms &&
                              formikBag.errors.terms
                              ? formikBag.errors.terms
                              : null
                          }
                        />
                        <div>
                          <p className="white-space-nowrap">{newTerms?.trim()?.split(/\s+/)?.filter(word => word)?.length}/200 words</p>                      <div className='d-flex justify-content-end'>
                            <div
                              onClick={newTerms.length === 0 ? null : () => addTerms(formikBag)}
                              className={`savebtn ${newTerms.length === 0 ? 'btndisabled' : 'pointer'}`}
                              style={{ pointerEvents: newTerms.length === 0 ? 'none' : 'auto' }}
                            >
                              Save
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className='error'>{
                        formikBag.errors.terms
                          ? formikBag.errors.terms
                          : null
                      }</p>
                      <ul className='my-5 list-style-none'>
                        {terms.map((term, index) => (
                          <li key={index} className="inptCont">
                            {/* Show the editing input if we are editing this item */}
                            {editingTermsIndex === index ? (
                              <div className='d-flex justify-content-between'>
                                <input
                                  type="text"
                                  value={editingTermsText}
                                  onChange={(e) => setEditingTermsText(e.target.value)}
                                />
                                <div onClick={() => saveTerms(formikBag)} className='savebtn'>Save</div>
                              </div>
                            ) : (
                              <div className='d-flex justify-content-between word-break-all font-italic'>
                                <span><BsDot /> {term}</span>
                                <div className='d-flex align-items-start '>
                                  <div className='font-#F8F8F8 border-0 pointer me-2 fs-20' onClick={() => {
                                    setEditingTermsIndex(index);
                                    setEditingTermsText(term);
                                  }}>
                                    <MdEdit />
                                  </div>
                                  <div className='bg-white border-0 pointer fs-20' onClick={() => deleteTerms(index, formikBag)}> <RiDeleteBin6Line /> </div>
                                </div>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div>
                    <button type="submit" className='savebtn' >Save</button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Formik>
      </div>
      {isLoading && <Overlay />}
    </div>

  )
}
