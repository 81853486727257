import React from 'react'
import { DashHeading } from '../Authentican/Profile/ProfileElements'
import { FaPlus } from 'react-icons/fa'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function SubAdmin() {
  const history = useHistory();

  return (
    <div className='hewe-cont'>
      <div className='flex-align-center  justify-content-between'>
        <div className="flex-align-center mb-0 ">
          <div className="dott me-3"></div>
          <DashHeading>Sub Admin Management</DashHeading>
        </div>
        <div>
          <button onClick={()=>history.push(`/sub-admin-management/add-subAdmin`)} className='savebtn flex-align-center'><FaPlus className='me-2' />Add SubAdmin</button>
        </div>
      </div>
    </div>
  )
}

export default SubAdmin