import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Overlay from '../../components/Overlay';
import { LoginButton } from '../Authentican/Signup/SignupElements';
import { InputDivide } from '../Authentican/Profile/ProfileElements';
import Input from '../../components/Input';
import { IoChevronBack } from 'react-icons/io5';
import { DashboardWrapper, DashHeading } from '../AccountManagement/AccountManagementElements';
import { Field, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { FaUserAlt } from 'react-icons/fa';
import { MdEmail, MdPhone } from 'react-icons/md';
import * as Yup from "yup";
import 'react-phone-input-2/lib/style.css';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Checkbox,
    TableRow,
    TablePagination,
    InputBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PhoneInput from "react-phone-input-2";
import { toast } from 'react-toastify';
import axios from "../../axios";
import "./style.scss"
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        minHeight: "50vh",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        textTransform: "capitalize",
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));

export const AddSubAdmin = () => {
    const history = useHistory()
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false)


    const [userData, setUserData] = useState({
        email: "",
        phoneNumber: "",
        countryCode: "+91",
        subAdminName: "",
        subAdminTitle: "",
        modules: [
            {
                moduleName: "Dashboard",
                access: false,
                permissions: { view: false, add: false, edit: false, delete: false, download: false, blockUnblock: false},
                disable: { view: false, add: true, edit: true, delete: true, download: true, blockUnblock: true },
            },
            {
                moduleName: "Account Management",
                access: false,
                permissions: { view: false, add: false, edit: false, delete: false, download: false, blockUnblock: false},
                disable: { view: false, add: true, edit: true, delete: false, download: false, blockUnblock: false },
            },
            {
                moduleName: "Reward Management",
                access: false,
                permissions: { view: false, add: false, edit: false, delete: false, download: false, blockUnblock: false},
                disable: { view: false, add: false, edit: false, delete: false, download:true, blockUnblock: false},
            },
            {
                moduleName: "Level Management",
                access: false,
                permissions: { view: false, add: false, edit: false, delete: false, download: false, blockUnblock: false},
                disable: { view: false, add: false, edit: false, delete: false, download:true, blockUnblock: false},
            },
            {
                moduleName: "Daily Achievement",
                access: false,
                permissions: { view: false, add: false, edit: false, delete: false, download: false, blockUnblock: false},
                disable: { view: false, add: false, edit: false, delete: false, download:true, blockUnblock: false},
            },
            {
                moduleName: "SubAdmin Management",
                access: false,
                permissions: { view: false, add: false, edit: false, delete: false, download: false, blockUnblock: false},
                disable: { view: false, add: false, edit: false, delete: false, download:true, blockUnblock: false},
            },
            {
                moduleName: "CMS Management",
                access: false,
                permissions: { view: false, add: false, edit: false, delete: false, download: false, blockUnblock: false},
                disable: { view: false, add: false, edit: false, delete: false, download:true, blockUnblock: false},
            },
            {
                moduleName: "Notification Management",
                access: false,
                permissions: { view: false, add: false, edit: false, delete: false, download: false, blockUnblock: false},
                disable: { view: false, add: false, edit: false, delete: false, download:true, blockUnblock: false},
            },
        ]
    })

    const handlePhoneChange = (value, data) => {
        console.log(data)
        const phoneNumber = value.slice(data.dialCode.length);

        setUserData((prev) => ({
            ...prev,
            countryCode: `+${data.dialCode}`,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prev) => ({ ...prev, [name]: value }));
    };

    const handleModuleChange = (index, key, value) => {
        setUserData((prev) => {
            const updatedModules = [...prev.modules];
            updatedModules[index][key] = value;
            return { ...prev, modules: updatedModules };
        });
    };

    const handlePermissionChange = (index, permissionKey, value) => {
        setUserData((prev) => {
            const updatedModules = [...prev.modules];
            updatedModules[index].permission[permissionKey] = value;
            return { ...prev, modules: updatedModules };
        });
    };


    const handleUserData = async (value) => {
        try {
            setIsLoading(true)
            console.log(value)
            console.log(JSON.stringify(value))
            const data = await axios.post(`/private/createSubAdmin`, value)
            toast.success(data?.data?.message);
            history.goBack();
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            toast.error(`${error.response.data?.errors[0].msg}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.log(error)
        }
    }

    const validationSchema = Yup.object().shape({
        subAdminName: Yup.string().min(3, "Too Short!").max(12, "Too Long!").required("Enter Sub Admin Name"),
        subAdminTitle: Yup.string().min(3, "Too Short!").max(12, "Too Long!").required("Enter Sub Admin Title"),
        email: Yup.string().email("Invalid Email").required("Enter Email Address"),
        phoneNumber: Yup.string().required("Enter Phone Number"),
    });

    return (
        <section className="hewe-cont addSubAdmin" >
            <div className="flex-align-center justify-content-between mb-4 w-100">
                <div className="flex-align-center ">
                    <IoChevronBack
                        size={32}
                        className="back-color"
                        onClick={() =>
                            history.goBack()}
                    />
                    <DashHeading>Add Sub Admin</DashHeading>
                </div>
            </div>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={userData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleUserData(values)}
                >
                    {(formikBag) => (
                        <form onSubmit={formikBag.handleSubmit}>
                            <div className="cont">
                                <p className="mb-4">Sub Admin Details</p>
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <label htmlFor="subAdminName">Sub Admin Name</label>
                                        <Input
                                            type="text"
                                            id="subAdminName"
                                            name="subAdminName"
                                            value={formikBag.values.subAdminName}
                                            onChange={formikBag.handleChange}
                                            className="form-control"
                                            placeholder="Enter Sub Admin Name"
                                        />
                                        {formikBag.touched.subAdminName && formikBag.errors.subAdminName && (
                                            <div className="error">{formikBag.errors.subAdminName}</div>
                                        )}
                                    </div>
                                    <div className="col-6 mb-3">
                                        <label htmlFor="subAdminTitle">Sub Admin Title</label>
                                        <Input
                                            type="text"
                                            id="subAdminTitle"
                                            name="subAdminTitle"
                                            value={formikBag.values.subAdminTitle}
                                            onChange={formikBag.handleChange}
                                            className="form-control"
                                            placeholder="Enter Sub Admin Title"
                                        />
                                        {formikBag.touched.subAdminTitle && formikBag.errors.subAdminTitle && (
                                            <div className="error">{formikBag.errors.subAdminTitle}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="mb-4">Contact Information</p>
                                    <div className="col-6 mb-4">
                                        <label htmlFor="email">Email</label>
                                        <Input
                                            type="text"
                                            id="email"
                                            name="email"
                                            value={formikBag.values.email}
                                            onChange={formikBag.handleChange}
                                            className="form-control"
                                            placeholder="Enter Email"
                                        />
                                        {formikBag.touched.email && formikBag.errors.email && (
                                            <div className="error">{formikBag.errors.email}</div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label>Phone Number</label>
                                        <div className="d-flex form-controlphone">
                                            <PhoneInput
                                                country={'in'}
                                                enableSearch={true}
                                                countryCodeEditable={false}
                                                className="phone-input"
                                                value={formikBag.countryCode}
                                                // country={phoneData.countryCodeName}
                                                style={{ width: "110px" }}
                                                onChange={handlePhoneChange}

                                            />
                                            <Input
                                                type="text"
                                                value={formikBag.values.phoneNumber}
                                                onChange={formikBag.handleChange}
                                                name="phoneNumber"
                                                placeholder="Enter phone number"
                                                className="form-control border-0"
                                                onKeyPress={(e) => {
                                                    // Prevent non-numeric characters
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                inputMode="numeric"
                                                pattern="[0-9]*"

                                            />
                                        </div>
                                        {formikBag.touched.phoneNumber && formikBag.errors.phoneNumber && (
                                            <div className="error">{formikBag.errors.phoneNumber}</div>
                                        )}
                                    </div>
                                </div>
                                <p className="note">
                                    <strong>Note:</strong> Sub Admin will receive an email with the new login credentials for panel access.
                                </p>
                            </div>
                            <div className="permission mt-4">
                                <h3>Manage Permission</h3>
                                <Paper>
                                    <TableContainer className={classes.tableMainContainer}>
                                        <Table>
                                            <TableHead>
                                                <TableRow className={classes.tableHeadingRow}>
                                                    <TableCell className={classes.tablseHeadingCell}>Access</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Module Name</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>View</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Add</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Edit</TableCell>
                                                    {/* <TableCell className={classes.tablseHeadingCell}>Update</TableCell> */}
                                                    <TableCell className={classes.tablseHeadingCell}>Block/UnBloack</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Delete</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Download</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {formikBag?.values?.modules?.map((module, index) => (
                                                    <TableRow key={module.moduleName}>
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                checked={formikBag.values.modules[index].access}
                                                                onChange={(e) =>
                                                                    formikBag.setFieldValue(`modules[${index}].access`, e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>{module.moduleName}</TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={formikBag.values.modules[index].disable.view}
                                                                checked={formikBag.values.modules[index].permissions.view}
                                                                onChange={(e) =>
                                                                    formikBag.setFieldValue(`modules[${index}].permissions.view`, e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={formikBag.values.modules[index].disable.add}
                                                                checked={formikBag.values.modules[index].permissions.add}
                                                                onChange={(e) =>
                                                                    formikBag.setFieldValue(`modules[${index}].permissions.add`, e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={formikBag.values.modules[index].disable.edit}
                                                                checked={formikBag.values.modules[index].permissions.edit}
                                                                onChange={(e) =>
                                                                    formikBag.setFieldValue(`modules[${index}].permissions.edit`, e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                        {/* <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                checked={formikBag.values.modules[index].permissions.update}
                                                                onChange={(e) =>
                                                                    formikBag.setFieldValue(`modules[${index}].permissions.update`, e.target.checked)
                                                                }
                                                            />
                                                        </TableCell> */}
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={formikBag.values.modules[index].disable.blockUnblock}
                                                                checked={formikBag.values.modules[index].permissions.blockUnblock}
                                                                onChange={(e) =>
                                                                    formikBag.setFieldValue(`modules[${index}].permissions.blockUnblock`, e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={formikBag.values.modules[index].disable.delete}
                                                                checked={formikBag.values.modules[index].permissions.delete}
                                                                onChange={(e) =>
                                                                    formikBag.setFieldValue(`modules[${index}].permissions.delete`, e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={formikBag.values.modules[index].disable.download}
                                                                checked={formikBag.values.modules[index].permissions.download}
                                                                onChange={(e) =>
                                                                    formikBag.setFieldValue(`modules[${index}].permissions.download`, e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>

                                                ))}
                                            </TableBody>
                                            {console.log(formikBag)}
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                            <button type="submit" className="savebtn mt-5">
                                Submit
                            </button>
                        </form>
                    )}
                </Formik>
            </div>
            {isLoading && <Overlay />}

        </section>
    )
}
