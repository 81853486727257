import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Overlay from '../../components/Overlay';
import { LoginButton } from '../Authentican/Signup/SignupElements';
import { InputDivide } from '../Authentican/Profile/ProfileElements';
import Input from '../../components/Input';
import { IoChevronBack } from 'react-icons/io5';
import { DashboardWrapper, DashHeading } from '../AccountManagement/AccountManagementElements';
import { Field, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { FaUserAlt } from 'react-icons/fa';
import { MdEmail, MdPhone } from 'react-icons/md';
import * as Yup from "yup";
import 'react-phone-input-2/lib/style.css';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Checkbox,
    TableRow,
    TablePagination,
    InputBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PhoneInput from "react-phone-input-2";
import { toast } from 'react-toastify';
import axios from "../../axios";
import "./style.scss"
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        minHeight: "50vh",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        textTransform: "capitalize",
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));

export const AddSubAdmin = () => {
    const history = useHistory()
    const classes = useStyles();


    const [phoneData, setPhoneData] = useState({
        countryCodeName: "in",
        countryCode: "+91",
    });

    console.log(phoneData)
    const handlePhoneChange = (value, data) => {
        console.log(data)
        const phoneNumber = value.slice(data.dialCode.length);
        setPhoneData({
            countryCodeName: data.countryCode,
            countryCode: `+${data.dialCode}`,
        });
    };
    const [userData, setUserData] = useState({
        email: "",
        phoneNumber: "",
        countryCode: phoneData.countryCode,
        subAdminName: "",
        subAdminTitle: "",
        modules: [
            {
                moduleName: "Dashboard",
                access: false,
                permission: { view: true, add: false, edit: false, delete: false, download: false },
            },
            {
                moduleName: "Account Management",
                access: false,
                permission: { view: false, add: false, edit: false, delete: false, download: false },
            },
            {
                moduleName: "Reward Management",
                access: false,
                permission: { view: false, add: false, edit: false, delete: false, download: false },
            },
            {
                moduleName: "Level Management",
                access: false,
                permission: { view: false, add: false, edit: false, delete: false, download: false },
            },
            {
                moduleName: "Daily Achievement",
                access: false,
                permission: { view: false, add: false, edit: false, delete: false, download: false },
            },
            {
                moduleName: "SubAdmin Management",
                access: false,
                permission: { view: false, add: false, edit: false, delete: false, download: false },
            },
            {
                moduleName: "CMS Management",
                access: false,
                permission: { view: false, add: false, edit: false, delete: false, download: false },
            },
            {
                moduleName: "Notification Management",
                access: false,
                permission: { view: false, add: false, edit: false, delete: false, download: false },
            },
        ]
    })

    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prev) => ({ ...prev, [name]: value }));
    };

    const handleModuleChange = (index, key, value) => {
        setUserData((prev) => {
            const updatedModules = [...prev.modules];
            updatedModules[index][key] = value;
            return { ...prev, modules: updatedModules };
        });
    };

    const handlePermissionChange = (index, permissionKey, value) => {
        setUserData((prev) => {
            const updatedModules = [...prev.modules];
            updatedModules[index].permission[permissionKey] = value;
            return { ...prev, modules: updatedModules };
        });
    };

    const handleSubmit = () => {
        console.log("Submitted Data:", userData);
    };
    const handleUserData = (value) => {
        try {
            console.log(value)
        } catch (error) {
            console.log(error)
        }
    }

    const validationSchema = Yup.object().shape({
        subAdminName: Yup.string().min(3, "Too Short!").max(12, "Too Long!").required("Required"),
        subAdminTitle: Yup.string().min(3, "Too Short!").max(12, "Too Long!").required("Required"),
        email: Yup.string().email("Invalid Email").required("Required"),
        phoneNumber: Yup.string().required("Required"),
      });

    return (
        <section className="hewe-cont addSubAdmin" >
            <div className="flex-align-center justify-content-between mb-4 w-100">
                <div className="flex-align-center ">
                    <IoChevronBack
                        size={32}
                        className="back-color"
                        onClick={() =>
                            history.goBack()}
                    />
                    <DashHeading>Add Sub Admin</DashHeading>
                </div>
            </div>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={userData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleUserData(values)}
                >
                    {(formikBag) => (
                        <form onSubmit={formikBag.handleSubmit}>
                            <div className="cont">
                                <p className="mb-4">Sub Admin Details</p>
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <label htmlFor="subAdminName">Sub Admin Name</label>
                                        <Input
                                            type="text"
                                            id="subAdminName"
                                            name="subAdminName"
                                            value={formikBag.values.subAdminName}
                                            onChange={formikBag.handleChange}
                                            className="form-control"
                                            placeholder="Enter Sub Admin Name"
                                        />
                                        {formikBag.touched.subAdminName && formikBag.errors.subAdminName && (
                                            <p className="error-text">{formikBag.errors.subAdminName}</p>
                                        )}
                                    </div>
                                    <div className="col-6 mb-3">
                                        <label htmlFor="subAdminTitle">Sub Admin Title</label>
                                        <Input
                                            type="text"
                                            id="subAdminTitle"
                                            name="subAdminTitle"
                                            value={formikBag.values.subAdminTitle}
                                            onChange={formikBag.handleChange}
                                            className="form-control"
                                            placeholder="Enter Sub Admin Title"
                                        />
                                        {formikBag.touched.subAdminTitle && formikBag.errors.subAdminTitle && (
                                            <p className="error-text">{formikBag.errors.subAdminTitle}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="mb-4">Contact Information</p>
                                    <div className="col-6 mb-4">
                                        <label htmlFor="email">Email</label>
                                        <Input
                                            type="text"
                                            id="email"
                                            name="email"
                                            value={formikBag.values.email}
                                            onChange={formikBag.handleChange}
                                            className="form-control"
                                            placeholder="Enter Email"
                                        />
                                        {formikBag.touched.email && formikBag.errors.email && (
                                            <p className="error-text">{formikBag.errors.email}</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label>Phone Number</label>
                                        <div className="d-flex">
                                        <PhoneInput
                                                    country={'in'}
                                                    enableSearch={true}
                                                    countryCodeEditable={false}
                                                    className="phone-input"
                                                    value={phoneData.countryCode}
                                                    // country={phoneData.countryCodeName}
                                                    style={{ width: "90px" }}
                                                    onChange={handlePhoneChange}
                                                />
                                            <Input
                                                type="text"
                                                value={formikBag.values.phoneNumber}
                                                onChange={formikBag.handleChange}
                                                name="phoneNumber"
                                                placeholder="Enter phone number"
                                                className="form-control"
                                            />
                                        </div>
                                        {formikBag.touched.phoneNumber && formikBag.errors.phoneNumber && (
                                            <p className="error-text">{formikBag.errors.phoneNumber}</p>
                                        )}
                                    </div>
                                </div>
                                <p className="note">
                                    <strong>Note:</strong> Sub Admin will receive an email with the new login credentials for panel access.
                                </p>
                            </div>
                            <div className="permission mt-4">
                                <h3>Manage Permission</h3>
                                <Paper>
                                    <TableContainer className={classes.tableMainContainer}>
                                        <Table>
                                            <TableHead>
                                                <TableRow className={classes.tableHeadingRow}>
                                                    <TableCell className={classes.tablseHeadingCell}>Access</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Module Name</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>View</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Add</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Edit</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Delete</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Download</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {formikBag?.values?.modules?.map((module, index) => (
                                                   <TableRow key={module.moduleName}>
                                                   <TableCell className={classes.textMiddle}>
                                                       <input
                                                           type="checkbox"
                                                           checked={formikBag.values.modules[index].access}
                                                           onChange={(e) =>
                                                               formikBag.setFieldValue(`modules[${index}].access`, e.target.checked)
                                                           }
                                                       />
                                                   </TableCell>
                                                   <TableCell className={classes.textMiddle}>{module.moduleName}</TableCell>
                                                   <TableCell className={classes.textMiddle}>
                                                       <input
                                                           type="checkbox"
                                                           checked={formikBag.values.modules[index].permission.view}
                                                           onChange={(e) =>
                                                               formikBag.setFieldValue(`modules[${index}].permission.view`, e.target.checked)
                                                           }
                                                       />
                                                   </TableCell>
                                                   <TableCell className={classes.textMiddle}>
                                                       <input
                                                           type="checkbox"
                                                           checked={formikBag.values.modules[index].permission.add}
                                                           onChange={(e) =>
                                                               formikBag.setFieldValue(`modules[${index}].permission.add`, e.target.checked)
                                                           }
                                                       />
                                                   </TableCell>
                                                   <TableCell className={classes.textMiddle}>
                                                       <input
                                                           type="checkbox"
                                                           checked={formikBag.values.modules[index].permission.edit}
                                                           onChange={(e) =>
                                                               formikBag.setFieldValue(`modules[${index}].permission.edit`, e.target.checked)
                                                           }
                                                       />
                                                   </TableCell>
                                                   <TableCell className={classes.textMiddle}>
                                                       <input
                                                           type="checkbox"
                                                           checked={formikBag.values.modules[index].permission.delete}
                                                           onChange={(e) =>
                                                               formikBag.setFieldValue(`modules[${index}].permission.delete`, e.target.checked)
                                                           }
                                                       />
                                                   </TableCell>
                                                   <TableCell className={classes.textMiddle}>
                                                       <input
                                                           type="checkbox"
                                                           checked={formikBag.values.modules[index].permission.download}
                                                           onChange={(e) =>
                                                               formikBag.setFieldValue(`modules[${index}].permission.download`, e.target.checked)
                                                           }
                                                       />
                                                   </TableCell>
                                               </TableRow>
                                               
                                                ))}
                                            </TableBody>
                                            {console.log(formikBag)}
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                            <button type="submit" className="savebtn mt-5">
                                Submit
                            </button>
                        </form>
                    )}
                </Formik>
            </div>
        </section>
    )
}
