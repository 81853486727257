import React from "react";
import styled from "styled-components/macro";
import { withRouter, NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";

const SidebarLink = styled(Link)`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0px 18px 18px;
  height: 45px;
  text-decoration: none;
  background: ${({ sidebarActive }) => (sidebarActive ? "#304312" : "#132400")};
  border-left: ${({ sidebarActive }) =>
    sidebarActive ? "3px solid grey" : "3px solid #132400"};
`;

const DropdownLink = styled(SidebarLink)`
  padding-left: 35px; /* Indent for child dropdown links */
  background: ${({ sidebarActive }) =>
    sidebarActive ? "#2a3a0f" : "transparent"}; /* Slightly darker */
  border-left: ${({ sidebarActive }) =>
    sidebarActive ? "3px solid grey" : "3px solid transparent"};

  &:hover {
    // background: #2e4411;
    color: #fff;
  }
`;


const SidebarLabel = styled.span`
  margin-left: 14px;
  font-size:15px;

  &:hover {
  font-weight:400;
    color:rgb(255, 255, 255);
  }
`;



const IconLabel = styled.div`
  width: 100%;
  border-radius: 1.5px;
  display: flex;
  align-items: baseline;
  font-weight: ${({ sidebarActive }) => (sidebarActive ? 700 : 200)};
  color: ${({ sidebarActive }) => (sidebarActive ? "#fff" : "white")};

  &:hover {
    cursor: pointer;
  }
`;

const SidebarIcon = styled.span`
  &:hover {
    color: #21afe6;
  }
`;


const SubMenu = ({
  userData,
  item,
  history,
  sidebar,
  setSidebar,
  activeDropdown,
  setActiveDropdown,
}) => {
  const showSidebar = () => setSidebar(!sidebar);
  const sidePathname = history.location.pathname.split("/")[1];

  const isChildActive = item.subNav?.some(
    (subItem) => sidePathname === subItem.path.split("/")[1]
  );

  const isActive =
    activeDropdown === item.title ||
    sidePathname === item.path.split("/")[1] ||
    isChildActive;

  const toggleSubnav = () => {
    if (isActive) {
      setActiveDropdown(null); 
    } else {
      // setActiveDropdown(item.title);
    }
  };

  return (
    <>
      <SidebarLink
        sidebarActive={isActive}
        to={item.path}
        onClick={(item.subNav && toggleSubnav) || (!sidebar ? showSidebar : "")}
      >
        <IconLabel sidebarActive={isActive}>
          <SidebarIcon>{item.icon}</SidebarIcon>
          <SidebarLabel
            style={{
              color: isActive ? "#fff" : "",
              fontWeight: isActive ? "400" : "",
            }}
          >
            {item.title}
          </SidebarLabel>
        </IconLabel>
        <div className="me-3">
          {item.subNav && isActive
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {isActive &&
        item.subNav?.map((subItem, index) => (
          <DropdownLink
            to={subItem.path}
            sidebarActive={sidePathname === subItem.path.split("/")[1]}
            key={index}
          >
            <IconLabel
              style={{
                color: sidePathname === subItem.path.split("/")[1] ? "#fff" : "",
              }}
            >
              <SidebarLabel
                style={{
                  color: sidePathname === subItem.path.split("/")[1] ? "#fff" : "",
                  fontWeight: sidePathname === subItem.path.split("/")[1] ? "600" : "",
                }}
              >
                {subItem.title}
              </SidebarLabel>
            </IconLabel>
          </DropdownLink>
        ))}
    </>
  );
};




const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    sidebar: state.sidebar,
    activeDropdown: state.activeDropdown,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    },
    setActiveDropdown: (dropdown) => {
      dispatch({
        type: actionTypes.SET_ACTIVE_DROPDOWN,
        activeDropdown: dropdown,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubMenu));
