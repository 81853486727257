import React, { useEffect, useState, useRef } from 'react'
import { DashHeading } from '../AccountManagement/AccountManagementElements'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import "./style.scss"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import { get } from "lodash";
import { Daily1, Daily2, Daily3, Filter, TargetSummary } from '../../components/svg';
import { Edit } from '@material-ui/icons';
import { FaAngleDown, FaCheck, FaCross } from 'react-icons/fa';
import { BiCross, BiDownArrow, BiRightArrow } from 'react-icons/bi';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Pagination, PaginationItem } from "@material-ui/lab";
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { Stack } from "react-bootstrap";
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));
function Daily_Achievement() {
    const history = useHistory()
    const classes = useStyles();
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, SetTableData] = useState([]);
    // For Pagination
    const [page, setPage] = useState(0);
    const [statePage, setStatePage] = useState(null)
    console.log("one", statePage)
    // For Search
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        window.scrollTo(0, 0)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    // -----------filter--------------------------------


    // --------------edit --------------------
    const [editHealthTargets, setEditHealthTargets] = useState({});
    const [showButtons, setShowButtons] = useState({});

    const handleInputChange = (id, value) => {
        setEditHealthTargets((prev) => ({ ...prev, [id]: value }));
        setShowButtons((prev) => ({ ...prev, [id]: true }));
    };

    const handleCancel = (id, defaultValue) => {
        setEditHealthTargets((prev) => ({ ...prev, [id]: defaultValue }));
        setShowButtons((prev) => ({ ...prev, [id]: false }));
    };

    // -----------------------------------------------------

    const [adminData, setAdminData] = useState()




    // -----------------edit save------------------------------------
    let handleSave = async (id, value) => {
        setIsLoading(true)

        console.log(id, value, "===>>")
        try {
            let formData = {
                healthTarget: Number(value)
            }
            const { data } = await axios.post(
                `/private/editAchievement/${id}`, formData
            );
            getData()
            setIsLoading(false)
            console.log(`Saving for ID ${id}:`, value);
            setShowButtons((prev) => ({ ...prev, [id]: false }));
        } catch (error) {
            setIsLoading(false)

        }
    }



    const month = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 }
    ];

    const year = [
        { label: 2025, value: 2025 },
        { label: 2026, value: 2026 },
        { label: 2027, value: 2027 },
        { label: 2028, value: 2028 },
        { label: 2029, value: 2029 },
        { label: 2030, value: 2030 },
        { label: 2031, value: 2031 },
        { label: 2032, value: 2032 },
        { label: 2033, value: 2033 },
        { label: 2034, value: 2034 },
        { label: 2035, value: 2035 }
    ];

    const days = [
        { label: "Week 1", value: "1-7" },
        { label: "Week 2", value: "8-14" },
        { label: "Week 3", value: "15-21" },
        { label: "Week 4", value: "22-28" },
        { label: "Week 5", value: "29-31" },
    ]



    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    const currentDay = today.getDate();

    // Determine the week of the current day
    const getWeek = (day) => {
        if (day >= 1 && day <= 7) return "1-7";
        if (day >= 8 && day <= 14) return "8-14";
        if (day >= 15 && day <= 21) return "15-21";
        if (day >= 22 && day <= 28) return "22-28";
        return "29-31";
    };

    const defaultWeek = getWeek(currentDay);

    const [filter, setFilterValue] = useState({
        year: currentYear,
        month: currentMonth,
        startDay: defaultWeek.split("-")[0],
        endDay: defaultWeek.split("-")[1]
    });

    const handleYearChange = (event) => {
        setFilterValue((prev) => ({
            ...prev,
            year: event.target.value,
            startDay: "1",
            endDay: "7"
        }));
    };

    const handleMonthChange = (event) => {
        setFilterValue((prev) => ({
            ...prev,
            month: event.target.value,
            startDay: "1",
            endDay: "7"

        }));
    };

    const handleDaySelection = (value) => {
        const [startDay, endDay] = value.split("-");
        setFilterValue((prev) => ({
            ...prev,
            startDay,
            endDay
        }));
    };


    const getData = async () => {
        setIsLoading(true)
        try {
            const { data } = await axios.get(
                `/private/AllAchievement?page=${page + 1}&limit=${15}&year=${filter?.year}&month=${filter?.month}&startDate=${filter?.startDay}&endDate=${filter.endDay}`
            );
            console.log(data?.data?.docs)
            setAdminData(data?.data?.adminData)
            setPaginationData(data?.data?.data);
            SetTableData(data?.data?.data?.docs);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        getData();
    }, [filter]);

    return (
        <>
            <div className='hewe-cont'>
                <div className='statdaily'>
                    <div className='row'>
                        <div className='col-4 p-3'>
                            <div className='cards'>
                                <div>
                                    <h2>Health Target  <span>(Current week)</span></h2>
                                    <h3>{adminData?.week[0]?.Total} miles</h3>
                                </div>
                                <div>
                                    <Daily1 />
                                </div>
                            </div>
                        </div>
                        <div className='col-4 p-3'>
                            <div className='cards'>
                                <div>
                                    <h2>Health Target  <span>(Current Month)</span></h2>
                                    <h3>{adminData?.month} miles</h3>
                                </div>
                                <div>
                                    <Daily2 />
                                </div>
                            </div>
                        </div>
                        <div className='col-4 p-3'>
                            <div className='cards'>
                                <div>
                                    <h2>Consistent Users Achieving Target</h2>
                                    <h3>{adminData?.activeUser}</h3>
                                </div>
                                <div>
                                    <Daily3 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-align-center justify-content-between mb-4 w-100">
                    <div className="flex-align-center ">
                        <div className="dott me-3"></div>
                        <DashHeading>Daily Achievement</DashHeading>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='d-flex'>
                            <div>
                                <select className='selectBtn' value={filter.year} onChange={handleYearChange}>
                                    {year.map((y) => (
                                        <option key={y.value} value={y.value}>
                                            {y.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <select className='selectBtn' value={filter.month} onChange={handleMonthChange}>
                                    {month.map((m) => (
                                        <option key={m.value} value={m.value}>
                                            {m.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="ExportXls" onClick={() => history.push("/daily-achievement/add-achievement")}>+ Add Target</div>
                    </div>
                </div>
                <div className="py-4">
                    {days.map((item) => (
                        <button
                            key={item.value}
                            className={`canclebtn me-3 ${`${filter.startDay}-${filter.endDay}` === item.value
                                ? "active"
                                : ""
                                }`}
                            onClick={() => handleDaySelection(item.value)}
                        >
                            {item.label}
                        </button>
                    ))}
                </div>

                {tableData?.length > 0 ? (<>
                    <Paper className={classes.paperTableHeight}>
                        <>
                            <TableContainer
                                className={classes.tableMainContainer}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Day
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Action
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Date
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Health Target(miles)
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData.map((category, index) => (
                                            <TableRow key={category._id}>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{index + 1 + rowsPerPage * page}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div className='pointer' onClick={() => history.push(`details-achievement/${category._id}`, { state: { date: category.date, healthTarget: category.healthTarget } })}>
                                                        <p style={{ color: "rgb(116, 165, 24)" }}><TargetSummary /><span className='ms-2 underline'>Target  Achiever</span></p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>
                                                        <div>
                                                            <div>
                                                                {get(category, 'date', 'N/A')
                                                                    ? new Date(get(category, 'date')).toLocaleDateString("en-GB", {
                                                                        weekday: 'long',
                                                                        day: '2-digit',
                                                                        month: 'long',
                                                                        year: 'numeric'
                                                                    }).replace(/ (\d{2}) /, ', $1 ')
                                                                    : 'N/A'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                {Date.now() >= new Date(category.date) ? (
                                                    <TableCell className={classes.textMiddle}>
                                                        {get(category, 'healthTarget')} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </TableCell>
                                                ) : (
                                                    <TableCell className={classes.textMiddle}>
                                                        <div className="dailyEdit flex-items-center justify-content-center">
                                                            <input
                                                                type="number"
                                                                min="0"
                                                                className="p-2"
                                                                value={editHealthTargets[category._id] ?? category.healthTarget}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (value >= 0 || value === "") {
                                                                        handleInputChange(category._id, value);
                                                                    }
                                                                }}
                                                            />
                                                            <div className="dailyEditbtn ms-3">
                                                                <Edit />
                                                            </div>
                                                            {showButtons[category._id] && (
                                                                <>
                                                                    <button
                                                                        className="check"
                                                                        disabled={editHealthTargets[category._id] === category.healthTarget}
                                                                        onClick={() => handleSave(category._id, editHealthTargets[category._id])}
                                                                    >
                                                                        <FaCheck />
                                                                    </button>
                                                                    <button
                                                                        className="cross"
                                                                        onClick={() => handleCancel(category._id, category.healthTarget)}
                                                                    >
                                                                        x
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                )}

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </>
                    </Paper>
                </>) : (
                    <div className="emptyTable">
                        No Data Found
                    </div>
                )}

            </div>
            {isLoading && <Overlay />}

        </>
    )
}

export default Daily_Achievement