import React, { useEffect, useState } from 'react'
import { MdArrowBackIosNew, MdModeEdit } from 'react-icons/md'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { FaPlus, FaRegEdit, FaRegEye } from "react-icons/fa";
import axios from "../../../axios"
import { toast } from 'react-toastify';
import Overlay from '../../Overlay';
import "../rewardspin.scss"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    makeStyles,
} from "@material-ui/core";
import { BsEye } from 'react-icons/bs';
import CustomizedSwitches from '../../togglebtn';
import CustomizedSwitchesProbility from '../../ProbilityToggleButton';
import { IoChevronBack } from 'react-icons/io5';
import { DateFormater } from '../../dateFormater';
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#00000080",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    button: {
        textAlign: "center",
        backgroundColor: "#88AE40",
        padding: "7px 30px",
        color: "white",
        width: "250px",
        border: "none",
        borderRadius: "4px"
    },
}));
export default function RewardHistory() {
    const [stateData, setStateData] = useState()
    const param = useParams();
    const { state } = useLocation();
    useEffect(() => {
        setStateData(state?.state)
    }, [])
    console.log(stateData);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const [tableData, setTableData] = useState();
    const [totalReward, setTotalReward] = useState();
    const [recivedReward, setRecivedReward] = useState();
    const [voucherCode, setVoucherCode] = useState()
    const history = useHistory();
    const handleResponse = async (id) => {
        setIsLoading(true);
        try {
            const data = await axios.get(`/private/history/${param?.id}`);
            console.log(data)
            setTableData(data?.data?.data?.data);
            setTotalReward(data?.data?.data?.totalReward);
            setRecivedReward(data?.data?.data?.recivedReward);
            setVoucherCode(data?.data?.data?.voucherCode)
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleResponse();
    }, []);
    return (
        <div className="hewe-cont">
            <div className="referralcont">
                <div className="flex-align-center justify-content-between">
                    <div className='flex-align-center'>
                        <IoChevronBack
                            size={40}
                            onClick={() =>
                                history.push("/other-reward")
                            }
                        />
                        <h3 className="font-dark-green mb-0 fs-22 main-heading">History</h3>
                    </div>
                </div>
                <div className="mt-3 ms-3 d-flex flex-wrap historystat">
                    <h5>Reward Name: <strong>{stateData?.rewardName}</strong></h5>

                    {stateData?.brandName && (
                        <>
                            <h5 className="mx-2">|</h5>

                            <h5>Brand Name: <strong>{stateData?.brandName}</strong></h5>
                        </>
                    )}

                    <h5 className="mx-2">|</h5>
                    <h5>
                        Distributed Qty:{" "}
                        <strong>
                            {recivedReward}
                            {totalReward == "" && <span>/{totalReward}</span>}
                        </strong>
                    </h5>



                    {stateData?.rewardExpireDate && <>
                        <h5 className="mx-2">|</h5>
                        <h5 className='flex-align-center'>Expire Date:<strong className='ps-1'><DateFormater item={stateData?.rewardExpireDate} /></strong></h5>
                    </>}

                    {voucherCode && (
                        <>
                            <h5 className="mx-2">|</h5>
                            <h5>Voucher Code: <strong>{voucherCode}</strong></h5>
                        </>
                    )}
                </div>

                {tableData?.length > 0 ? (<>
                    <Paper className="mt-4 ms-3">
                        <>
                            <TableContainer className={classes.tableMainContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                User Name
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Received On
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Claimed On
                                            </TableCell>

                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Source
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Action Taken
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{index + 1}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.profileName}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <DateFormater item={item?.createdAt} />
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.claimedAt}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div > {item?.source}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.actionTaken}</div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    </Paper>
                </>) : (
                    <div className="emptyTable">
                        No Data Found
                    </div>
                )}
            </div>
            {isLoading && <Overlay />}
        </div>
    )
}
