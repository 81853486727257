import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import { IoChevronBack } from 'react-icons/io5';
import { DashHeading } from '../AccountManagement/AccountManagementElements';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from "../../axios";
import Overlay from "../../components/Overlay";
function AboutUs() {
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const [data, setData] = useState("")
    function decodeCustomString(encodedString) {
        const cleanedString = encodedString.replace(/(?:\b0=\b|&\d+=)/g, '').trim();
        return decodeURIComponent(cleanedString);
    }

    const getData = async () => {
        try {
            setIsLoading(true)
            const data = await axios.get(
                `/private/findCms/?type=AboutUs`
            );

            const jsonString = data?.data?.data[0]?.content;
            const parsedData = JSON.parse(jsonString);
            const name = Object.keys(parsedData)[0].trim();
            setData(name);
            toast.success(data?.data?.message);

            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getData()
    }, [])

    console.log(data)
    const updatePrivacy = async (values) => {
        setIsLoading(true)

        console.log(values.content)
        try {
            const data = await axios.post(`/private/updateCms?type=AboutUs`,
                values.content,
            );
            getData();
            toast.success(data?.data?.message);

            setIsLoading(false)
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
            <div className='hewe-cont'>
                <div className="flex-align-center mb-5">
                    <div className="dott me-3"></div>
                    <DashHeading>About Us</DashHeading>
                </div>

                <Formik
                    enableReinitialize
                    initialValues={{ content: data }}
                    onSubmit={(values) => updatePrivacy(values)}
                >
                    {({ values, setFieldValue, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <JoditEditor
                                config={{
                                    askBeforePasteFromWord: false,
                                    askBeforePasteHTML: false,
                                    defaultActionOnPaste: "",
                                    readonly: false,
                                    toolbarStyle: {
                                        backgroundColor: "red",
                                    },
                                }}
                                value={values.content}
                                onBlur={(newContent) => {
                                    setFieldValue("content", newContent);
                                }}
                            />
                            <div className="mt-4">
                                <button type="submit" className="savebtn">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="canclebtn ms-3"
                                    onClick={() => {
                                        setFieldValue("content", "");
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>

            </div>
            {isLoading && <Overlay />}

        </>
    )
}

export default AboutUs