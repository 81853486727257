import React, { useEffect, useState } from 'react'
import axios from "../../axios";
import "./style.scss"
import { toast } from 'react-toastify';

function ReferralAndEarn() {
  const [formData, setFormData] = useState({
    sender: { id: "66b9f0464b802321a3a2697a", value: "" },
    receiver: { id: "66b9f0374b802321a3a26974", value: "" },
   
  });

  const fetchRewardValue = async (key) => {
    try {
      const res = await axios.get(`/findRewardValueById/${formData[key].id}`);
      setFormData((prev) => ({
        ...prev,
        [key]: { ...prev[key], value: res.data.data[0].value },
      }));
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const updateRewardValue = async (key) => {
    try {
      const res = await axios.post("/private/setRewardType", formData[key]);
      toast.success(res.data.message);
      fetchRewardValue(key); // Refresh the value after update
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRewardValue("sender");
    fetchRewardValue("receiver");
   
  }, []);


  return (
    <>
      <div className='hewe-cont'>
        <div className='referralcont'>
          <div className='flex-align-center'>
            <div className="dott me-3"></div>
            <p className='main-heading'>Refer & Earn</p>
          </div>
          <div className='ps-4'>
          <div className='desc my-4'>
          <p>Define the reward users will earn for each successfull referral. Customize the hewe coins they recieved based on their referral activity</p>
          </div>
          <div className="refcont">
            <h2>For Sender</h2>
            <div className='flex-align-center mt-5'>
              <h4 className='mb-0 perrefer'>per refer = </h4>
              <div className='ms-3'>
                <label htmlFor="Total Hewe coins">Total Hewe coins</label>
                <div>
                <input
                type="text"
                placeholder="Enter hewe coins"
                value={formData.sender.value}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    sender: { ...prev.sender, value: e.target.value },
                  }))
                }
              /> </div>
              </div>
            </div>
            <button className='mt-4' onClick={() => updateRewardValue("sender")}>Update</button>
          </div>
          <div className="refcont">
            <h2>For Receiver</h2>
            <div className='flex-align-center mt-5'>
              <h4 className='mb-0 perrefer'>per refer = </h4>
              <div className='ms-3'>
                <label htmlFor="Total Hewe coins">Total Hewe coins</label>
                <div>
                <input
                type="text"
                placeholder="Enter hewe coins"
                value={formData.receiver.value}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    receiver: { ...prev.receiver, value: e.target.value },
                  }))
                }
              /> </div>
              </div>
            </div>
            <button className='mt-4' onClick={() => updateRewardValue("receiver")}>Update</button>
          </div>
          </div>
          

          
          
        </div>
      </div>

    </>
  )
}

export default ReferralAndEarn;