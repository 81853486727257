import React, { useEffect, useState } from 'react'
import { MdArrowBackIosNew, MdModeEdit } from 'react-icons/md'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { FaPlus, FaRegEdit, FaRegEye, FaRegTrashAlt, FaTrash } from "react-icons/fa";
import axios from "../../../axios"
import { toast } from 'react-toastify';
import Overlay from '../../Overlay';
import "../rewardspin.scss"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    makeStyles,
} from "@material-ui/core";
import { BsEye } from 'react-icons/bs';
import CustomizedSwitches from '../../togglebtn';
import { useStyles } from '../../rewardTableTheme';
import { DateFormater } from '../../dateFormater';

export default function InAppReward() {
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();

    const [tableData, setTableData] = useState();
    const history = useHistory();
    const handleRespose = async () => {
        setIsLoading(true)
        try {
            const data = await axios.get("/private/getRewardByCategory/2");
            console.log(data)
            setTableData(data?.data?.data);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.loog(error)
        }
    }
    useEffect(() => {
        handleRespose()
    }, [])

    const changeStatus = async (id, status) => {
        setIsLoading(true)
        try {
            const formData = {
                status: status
            }
            const data = await axios.put(`private/changeRewardStatus/${id}`, formData);
            handleRespose()
            toast.success(data?.data?.message)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    console.log(tableData)
    const deleteReward = async (id) => {
        setIsLoading(true)
        try {
            console.log(id)
            const data = await axios.delete(`private/deleteReward/${id}`);
            handleRespose()
            setIsLoading(false)
            toast.success(data?.data?.message)
        } catch (error) {

            setIsLoading(false)
        }
    }
    return (
        <div className="mt-3">
            <div className="referralcont">
                <div className="flex-align-center justify-content-between">
                    <div className='flex-align-center'>
                        <h4 className="font-dark-green mb-0 fs-22">In App Reward</h4>
                    </div>
                    <div>
                        <button className='flex-align-center addbtn' onClick={() => history.push("/other-reward/create-inApp-reward")}><FaPlus className='me-2' /> Add InApp Reward</button>
                    </div>
                </div>

                {tableData?.length > 0 ? (<>
                    <Paper className="mt-4" style={{ position: "relative", height: "60vh", overflowY: "scroll" }}>
                        <>
                            <TableContainer className={classes.tableMainContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Reward Name
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Status
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Available Qty
                                            </TableCell>
                                            {/* <TableCell className={classes.tablseHeadingCell}>
                                            Reward Expiry
                                        </TableCell> */}
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Details
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{index + 1}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.rewardAmount} {item?.rewardName}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div className='d-flex align-items-center justify-content-center  ms-4'>
                                                        <CustomizedSwitches item={item} changeStatus={changeStatus} />
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.quantity}</div>
                                                </TableCell>
                                                {/* <TableCell className={classes.textMiddle}>
                                                <DateFormater item={item?.rewardExpireDate} />
                                            </TableCell> */}
                                                <TableCell className={classes.textMiddle}>
                                                    <div className='historybtn' onClick={() => history.push(`/other-reward/history/${item?._id}`, { state: item })}><BsEye /> history</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>

                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        {/* <CustomizedSwitches item={item} changeStatus={changeStatus} /> */}
                                                        <MdModeEdit className='actionicon me-3' onClick={() => history.push(`/other-reward/edit-inApp-reward/${item?._id}`)} />
                                                        <FaRegTrashAlt className='deleteicon me-3' onClick={() => deleteReward(item?._id)} />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    </Paper>
                </>) : (
                    <div className="emptyTable">
                        No Data Found
                    </div>
                )}
            </div>
            {isLoading && <Overlay />}
        </div>
    )
}
