import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import "./style.scss"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import { get } from "lodash";
import { Daily1, Daily2, Daily3, DtAndroidSvg, DtAppleSvg, Filter, TargetSummary } from '../../components/svg';
import { Edit } from '@material-ui/icons';
import { FaAngleDown, FaArrowRight, FaCheck, FaCross } from 'react-icons/fa';
import { BiCross, BiDownArrow, BiRightArrow } from 'react-icons/bi';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { DashboardHeading, DashHeading } from '../Dashboard/DashboardElement';
import { IoChevronBack, IoEarth } from 'react-icons/io5';
import { Pagination, PaginationItem, Stack } from '@mui/material';
import { FiArrowLeft } from 'react-icons/fi';
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));
function Purchase() {
    const history = useHistory()
    const { state } = useLocation();
    const param = useParams();
    const classes = useStyles();
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, SetTableData] = useState([]);
    // For Pagination
    const [page, setPage] = useState(1);
    const [statePage, setStatePage] = useState(null)
    console.log("one", statePage)
    // For Search
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        window.scrollTo(0, 0)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    // -----------filter--------------------------------
    const [filter, setFilterValue] = useState({
        purchaseType: [],
        currentMonth: "0",
        lastWeek: "0",
        startDate: "",
        endDate: "",
    })

    const [selectedOption, setSelectedOption] = useState(0);

    console.log(selectedOption)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    const getData = async () => {
        setIsLoading(true)
        try {
            let formData = {
                ...filter
            }
            const { data } = await axios.post(
                `/private/purchaseHistory/${param.id}?page=${page}&limit=${limit}`, formData
            );
            setIsDropdownOpen(false);
            console.log(data?.data)
            setPaginationData(data?.data);
            SetTableData(data?.data?.docs);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }



    useEffect(() => {
        getData();
    }, [page]);


    //    -------------filter--------------------------------------------

    const handleSelectChange = (value) => {
        setFilterValue((prevFilter) => {
            if (value === 1) {
                return { currentMonth: "1", last_week: "0", startDate: "", endDate: "" };
            } else if (value === 2) {
                return { currentMonth: "0", last_week: "1", startDate: "", endDate: "" };
            } else if (value === 3) {
                return { currentMonth: "0", last_week: "0", startDate: "", endDate: "" };
            }
            return prevFilter;
        });
        setSelectedOption(value);
    };


    const handleDateChange = (dates) => {
        console.log("==>>>", dates)
        if (Array.isArray(dates)) {
            const [startDate, endDate] = dates;
            setFilterValue((prevFilter) => ({
                ...prevFilter,
                startDate: startDate,
                endDate: endDate,
            }));
        }
    };

    console.log("filter ===>>>", filter.startDate)

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const cancelFilter = () => {
        setFilterValue({ ...filter });
        setIsDropdownOpen(false);
    };

    const handleCheckboxChange = (e, value) => {
        setFilterValue((prevState) => {
            const updatedPurchaseType = e.target.checked
                ? [...prevState.purchaseType, value]
                : prevState.purchaseType.filter((item) => item !== value);

            return { ...prevState, purchaseType: updatedPurchaseType };
        });
    };
    //    ---------filter end--------------------------------

    console.log(tableData)
    return (
        <div className='hewe-cont'>

            <div className="flex-align-center justify-content-between mb-4 w-100">
                <div className="flex-align-center ">
                    <IoChevronBack
                        size={32}
                        className="back-color"
                        onClick={() =>
                            history.goBack({
                                state: { page: state?.state?.page },
                            })
                        }
                    />
                    <h3 className='font-dark-green mb-0 fs-22 main-heading'>Total Spending</h3>
            </div>
                <div className='d-flex align-items-center'>
                    <div className="custom-dropdown-container">
                        <button className='filtertbn' onClick={toggleDropdown}>
                            <div className='d-flex align-items-center'><Filter /> <p className="ms-1">Filter</p></div>   <span><FaAngleDown /></span>
                        </button>

                        {isDropdownOpen && (
                            <div className="custom-dropdown">
                                <div>
                                    <h6 className='py-2'>purchaseType</h6>
                                    <div className='flex-align-center'>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "1")}
                                        />
                                        <label className='ms-2'>Real Money</label>
                                    </div>
                                    <div className='flex-align-center'>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "2")}
                                        />
                                        <label className='ms-2'>Hewe Coin</label>
                                    </div>
                                </div>
                                <div
                                    className={`dropdown-item ${selectedOption === 1 ? "selected" : ""
                                        }`}
                                    onClick={() => handleSelectChange(1)}
                                >
                                    Current Month
                                </div>
                                <div
                                    className={`dropdown-item ${selectedOption === 2 ? "selected" : ""
                                        }`}
                                    onClick={() => handleSelectChange(2)}
                                >
                                    Last Week
                                </div>
                                <div
                                    className={`dropdown-item ${selectedOption === 3 ? "selected" : ""}`}
                                    onClick={() => handleSelectChange(3)}
                                >
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <p>By Date </p><BiRightArrow />
                                    </div>
                                </div>

                                {selectedOption === 3 && (
                                    <div className='datepcikers '>
                                        <DateRangePicker onChange={handleDateChange} dayPlaceholder='DD' monthPlaceholder='MM' yearPlaceholder='YYYY' value={[filter?.startDate, filter?.endDate]} />
                                    </div>
                                )}

                                <div className="dropdown-actions d-flex justify-content-between">
                                    <button className='apply bg-green' onClick={getData}>Apply</button>
                                    <button className='cancle' onClick={cancelFilter}>Cancel</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {tableData?.length > 0 ? (<>
            <Paper className={classes.paperTableHeight}>
                <>
                    <TableContainer
                        className={classes.tableMainContainer}
                    >
                        <Table>
                            <TableHead>
                                <TableRow className={classes.tableHeadingRow}>
                                    <TableCell className={classes.tablseHeadingCell}>
                                        S.No
                                    </TableCell>
                                    <TableCell className={classes.tablseHeadingCell}>
                                        Buy Date & Time
                                    </TableCell>
                                    <TableCell className={[classes.tablseHeadingCell]}>
                                        Package ID
                                    </TableCell>
                                    <TableCell className={classes.tablseHeadingCell}>
                                    Transaction ID
                                    </TableCell>
                                    <TableCell className={[classes.tablseHeadingCell]}>
                                        Purchase Type
                                    </TableCell>
                                    <TableCell className={[classes.tablseHeadingCell]}>
                                        Amount
                                    </TableCell>
                                    <TableCell className={[classes.tablseHeadingCell]}>
                                        Device ID
                                    </TableCell>
                                    <TableCell className={[classes.tablseHeadingCell]}>
                                        Device Type
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((category, index) => (
                                    <TableRow key={category._id}>
                                        <TableCell className={classes.textMiddle}>
                                            <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                                        </TableCell>
                                        <TableCell className={classes.textMiddle}>
                                            <div>
                                                {get(category, 'createdAt', 'N/A')
                                                    ? `${new Date(get(category, 'createdAt')).toLocaleDateString("en-US", {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit'
                                                    })} at ${new Date(get(category, 'createdAt')).toLocaleTimeString("en-US", {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: false
                                                    })}`
                                                    : 'N/A'}
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.textMiddle}>
                                            <div>{get(category, "skuId", "N/A")}</div>
                                        </TableCell>
                                        <TableCell className={classes.textMiddle}>
                                            <span className="truncate" >
                                                <Tooltip
                                                    title={get(category, "transectionId", "N/A")}
                                                    arrow
                                                >
                                                    <span className='d-block'>
                                                        {get(category, "transectionId", "N/A")}
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </TableCell>
                                        <TableCell className={classes.textMiddle}>
                                            <div>{category?.purchaseType=="1"?<div className='realMoney'>Real Money</div>:<div className='hewecoin'>Hewe Coins</div>}</div>
                                        </TableCell>
                                        <TableCell className={classes.textMiddle}>
                                            <div>
                                                {get(category, "price_currency_code", "N/A")} {" "}
                                                {
                                                    category?.price?.$numberDecimal
                                                        ? Number(category?.price?.$numberDecimal)
                                                        : (typeof category?.price === 'number' ? category?.price : "N/A")
                                                }
                                                {console.log(typeof category?.price)}
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.textMiddle}>
                                            <div>{get(category, "deviceId", "N/A")}</div>
                                        </TableCell>
                                        <TableCell className={classes.textMiddle}>
                                            {category?.deviceType === "3" ? (
                                                <DtAppleSvg />
                                            ) : category?.deviceType === "2" ? (
                                                <DtAndroidSvg />
                                            ) : (
                                                <IoEarth />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Stack spacing={2} className="flex-align-center justify-content-center w-100 py-3">
                        <Pagination
                            count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                            page={page}
                            onChange={(event, value) => handleChangePage(event, value)}
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                                    {...item}
                                />
                            )}
                        />
                    </Stack>
                </>
            </Paper>
            </>) : (
                        <div className="emptyTable">
                            No Data Found
                        </div>
                    )}
        </div>
    )
}

export default Purchase