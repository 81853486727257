import React, { useState } from 'react'
import { DashHeading } from '../AccountManagement/AccountManagementElements'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IoChevronBack } from 'react-icons/io5'
import { Field, Form, Formik } from 'formik';
import { addDailyAch } from '../../utils/validators';
import Input from '../../components/Input';
import { toast } from 'react-toastify';
import axios from "../../axios";
import Overlay from '../../components/Overlay';


function Add_Achievement() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        startDate: "",
        endDate: "",
        healthTarget: ""
    })

    const handleSubmit = async (value) => {
        setIsLoading(true)
        try {
            let formData = {
                startDate: value.startDate,
                endDate: value.endDate,
                healthTarget: Number(value.healthTarget)
            }
            const data = await axios.post("/private/createAchievement", formData);

            toast.success(data?.data?.message);
            history.push("/daily-achievement")
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error.response.data?.errors[0].msg)
        }
    }
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    return (
        <div className='hewe-cont'>
            <div className="flex-align-center justify-content-between mb-4 w-100">
                <div className="flex-align-center ">
                    <IoChevronBack
                        size={32}
                        className="back-color"
                        onClick={() =>
                            history.goBack()}
                    />
                    <DashHeading>Add Target</DashHeading>
                </div>
            </div>
            <Formik
                enableReinitialize
                initialValues={formData}
                validate={addDailyAch}
                validateOnChange
                onSubmit={(values) => handleSubmit(values)}
            >
                {(formikBag) => {
                    return (
                        <Form className="p-3">
                            <div className="bg-white p-4">
                                <div className="row">
                                    <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                                        <label htmlFor="" placeholder='Select Date' >Start Date</label>
                                        <Field className="inptCont">
                                            {({ field }) => (
                                                <Input
                                                    {...field}
                                                    type="date"
                                                    min={getTodayDate()}
                                                    value={formikBag.values.startDate}
                                                    onChange={(e) => {
                                                        formikBag.setFieldValue(
                                                            "startDate",
                                                            e.target.value
                                                        );
                                                    }}
                                                    error={
                                                        formikBag.touched.startDate &&
                                                            formikBag.errors.startDate
                                                            ? formikBag.errors.startDate
                                                            : null
                                                    }
                                                    className="form-control"
                                                />
                                            )}
                                        </Field>
                                    </div>

                                    <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                                        <label htmlFor="" placeholder='Select Date' >End Date</label>
                                        <Field className="inptCont">
                                            {({ field }) => (
                                                <Input
                                                    {...field}
                                                    type="date"
                                                    min={formikBag.values.startDate}
                                                    value={formikBag.values.endDate}
                                                    onChange={(e) => {
                                                        formikBag.setFieldValue(
                                                            "endDate",
                                                            e.target.value
                                                        );
                                                    }}
                                                    error={
                                                        formikBag.touched.endDate &&
                                                            formikBag.errors.endDate
                                                            ? formikBag.errors.endDate
                                                            : null
                                                    }
                                                    className="form-control"
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col-xxl-4 col-xl-6 d-lg-block d-none  col-sm-6 mb-4">
                                    </div>
                                    <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4" >
                                        <label>Health Target (In miles)</label>
                                        <div className="form-controlcont">
                                            <Field name="targetedWealth w-100">
                                                {({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type="Number"
                                                        value={formikBag.values.healthTarget}
                                                        onChange={(e) => {
                                                            formikBag.setFieldValue(
                                                                "healthTarget",
                                                                e.target.value
                                                            );
                                                        }}

                                                        className="form-control p-1"
                                                        placeholder="Enter Health Target"

                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <p className='error pt-1'>
                                            {formikBag.touched.healthTarget &&
                                                formikBag.errors.healthTarget
                                                ? formikBag.errors.healthTarget
                                                : null
                                            }
                                        </p>
                                    </div>
                                </div>


                                <div className="" style={{ padding: "1rem" }}>
                                    <button
                                        type="submit"
                                        className="savebtn"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {isLoading && <Overlay />}
        </div>
    )
}

export default Add_Achievement