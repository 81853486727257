import React, { useState, useEffect, useRef } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { FaMobile } from "react-icons/fa";
import { IoCaretDownOutline } from "react-icons/io5";
import { FaUserAlt } from "react-icons/fa";
import Cookies from "js-cookie";
import { AiFillSetting } from "react-icons/ai";
import { TiInfoOutline } from "react-icons/ti";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  SvgLogo,
} from "./NavbarElements";
import { FaSearch } from "react-icons/fa";
import {
  SearchContainer,
  SearchBar,
  SearchIcon,
  SearchInput,
} from "../../components/SearchBar/SearchElements";
import dashboardLogo from "../../images/hewe/logo.svg";
import "react-phone-input-2/lib/style.css";
import Overlay from "../Overlay";
import SidebarOverlay from "../SidebarOverlay";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import axios from "../../axios";
import { toast } from "react-toastify";
import dummyProfile from "../../assets/images/lady.png";
import { MdAccountCircle } from "react-icons/md";
import { RiLogoutBoxLine } from "react-icons/ri";
import { FaLock } from "react-icons/fa";
import { MdLock } from "react-icons/md";
import { SlClose } from "react-icons/sl";
import { Modal } from "../../components/Modal";
import "./Nav.css";
import { useHistory } from 'react-router-dom';

const Navbar = (props) => {
  const { userData, history, sidebar, setSidebar, setUsers } = props;
  const [openModal, setOpenModal] = useState(false);
  const accessToken = localStorage.getItem("token");

  const [defaultState, setDefaultState] = useState({
    isLogin: "",
    isSignup: "",
    isForget: "",
    isOtp: "",
    isReset: "",
    isProfileUpdate: "",
    isApproved: "",
    isRejected: "",
    isProfileComplete: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSidebarBackground, setIsLoadingSidebarBackground] =
    useState(false);
  const [DisplayMenu, setDisplayMenu] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const showBackOverlay = () =>
    setIsLoadingSidebarBackground(!isLoadingSidebarBackground);

  const leftBoxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (leftBoxRef.current && !leftBoxRef.current.contains(event.target)) {
        setDisplayMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    setIsLoading(true);
    setOpenModal(false);

    try {
      const { data } = await axios.delete(`/private/logout`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      localStorage.removeItem("id");
      localStorage.removeItem("email");
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      localStorage.removeItem("deviceId");
      Cookies.remove("showProfileSidebar");
      setUsers("");

      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      history.push("/");
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const showDisplaymenu = () => {
    setDisplayMenu(!DisplayMenu);
  };

  const handleProfile = () => {
    Cookies.set("showProfileSidebar", true, { expires: 365 });
    showDisplaymenu();
    history.push("/profile");
  };
  const handleProfile1 = () => {
    Cookies.set("showProfileSidebar", true, { expires: 365 });
    showDisplaymenu();
    history.push("/manageDevice");
  };

  const changedPassword = () => {
    Cookies.set("showProfileSidebar", true, { expires: 365 });
    showDisplaymenu();
    history.push("/changedPassword");
  };

  const leftBox = (
    <>
      <NavMenu ref={leftBoxRef}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => showDisplaymenu()}
          >
            <div className="nav-btn-logo">
              <SvgLogo
                className="profile-image"
                src={
                  userData.profileImage ? userData.profileImage : dummyProfile
                }
              />
            </div>
            <div className="me-5 text-start">
              <div className="profile-name fw-600">{`${userData.firstName} ${userData?.lastName??""}`}</div>
              <div className="profile-name fw-400">{`${userData.userType}`}</div>
            </div>
            <div>
              <IoCaretDownOutline />
            </div>
          </div>
          {DisplayMenu ? (
            <div className="nav-profile-container">
              <div className="nav-profile-section p-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <div className="nav-btn-logo">
                    <SvgLogo
                      className="profile-image"
                      src={
                        userData.profileImage
                          ? userData.profileImage
                          : dummyProfile
                      }
                    />
                  </div>
                  <div className="me-5 text-start">
                    <div className="profile-name text-black fw-600">{`${userData.firstName}`}</div>
                    <div className="profile-name text-black fw-400">{`${userData.userType}`}</div>
                  </div>
                  <div>
                    <IoCaretDownOutline />
                  </div>
                </div>

                <div className="d-flex align-items-center my-4">
                  <AiFillSetting style={{ color: "#00000080" }} />
                  <p className="font-2 fs-14 fw-400 ps-3">Account Setting</p>
                </div>
                <div className="customButton admintoggle" onClick={() => handleProfile()}>
                  <FaUserAlt style={{ fontSize: "1.2rem" }} />
                  <span>My Profile</span>
                </div>
                <div className="customButton admintoggle" onClick={() => handleProfile1()}>
                  <FaMobile style={{ fontSize: "1.2rem" }} />
                  <span>Manage Device</span>
                </div>
                <div
                  className="customButton admintoggle"
                  onClick={() => changedPassword()}
                >
                  <MdLock style={{ fontSize: "1.2rem" }} />
                  <span>Change Password</span>
                </div>
                <div
                  className="customButton"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  <RiLogoutBoxLine
                    style={{
                      transform: "rotateY(180deg)",
                      color: "#264503",
                      fontSize: "1.5rem",
                    }}
                  />
                  <span style={{ color: "red" }}>Logout</span>
                </div>
              </div>
            </div>
          ) : (
            false
          )}
        </div>
        <NavItem></NavItem>
      </NavMenu>
    </>
  );

  return (
    <>
      <IconContext.Provider value={{ color: "#ffffff" }}>
        <Nav>
          <NavbarContainer>
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                Cookies.set("showProfileSidebar", false, { expires: 365 });
              }}
            >
              <NavLogo to="/dashboard">
                <SvgLogo className="logoImage" src={dashboardLogo} />
              </NavLogo>
            </div>
            <MobileIcon
              onClick={() => {
                showBackOverlay();
                showSidebar();
              }}
            >
              <FaBars style={{ color: "#000000" }} />
            </MobileIcon>
            {leftBox}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>

      {/* Modal for logout  */}
      <Modal
        maxWidth="lg"
        width="360px"
        RoundedCorners={true}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div className="">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <TiInfoOutline style={{ color: "red" }} />
                <p className="ps-2" style={{ color: "red" }}>Log Out?</p>
              </div>
              <SlClose
                style={{
                  cursor: "pointer",
                }}
                onClick={() => { setOpenModal(false); }}
              />
            </div>
            <div className="logout-header">Are you sure want to Log Out ?</div>
          </div>
        }
        content={
          <>
            {/* <span className="are-you-sure">Are You Sure?</span> */}
            <div className="logout-button-group">
              <div className="logout-button" onClick={() => handleLogout()}>
                Yes
              </div>
              <div
                className="cancel-button"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                No
              </div>
            </div>
          </>
        }
      />

      {isLoading && <Overlay />}
      {!sidebar ? <SidebarOverlay /> : ""}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
