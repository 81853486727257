import React, { useEffect, useState, useRef } from 'react'
import { DashHeading } from '../AccountManagement/AccountManagementElements'
import { IoChevronBack } from 'react-icons/io5'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Field, Form, Formik } from 'formik';
import Input from '../../components/Input';
import { Modal } from '../../components/Modal';
import { MdModeEdit, MdOutlineClose } from 'react-icons/md';
import CreateLevelVoucher from '../../components/levelComponent/voucher/create-Level-vouchers';
import EditLevelVoucher from '../../components/levelComponent/voucher/edit-Level-voucher';
import CreateLevelInAppReward from '../../components/levelComponent/inAppReward/create-Level-appReward';
import CreateLevelAuditToken from '../../components/levelComponent/auditToken/create-Level-AuditToken';
import CreateLevelPremium from '../../components/levelComponent/premium/create-Level-premium';
import CreateLevelTrip from '../../components/levelComponent/trip/create-Level-trip';
import { addLevelValidation } from '../../utils/validators';
import axios from "../../axios";
import { toast } from 'react-toastify';
import Overlay from '../../components/Overlay';
import EditLevelInAppReward from '../../components/levelComponent/inAppReward/edit-Level-appReward';
import EditLevelAuditToken from '../../components/levelComponent/auditToken/edit-Level-AuditToken';
import EditLevelPremium from '../../components/levelComponent/premium/edit-Level-premium';
import EditLevelTrip from '../../components/levelComponent/trip/edit-Level-trip';
import { Delete } from '@material-ui/icons';
import { FaCaretDown } from 'react-icons/fa';

export default function EditLevel() {
    const param = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [rewardType, setRewardType] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [categoryName, setCategoryName] = useState("1")

    const [compData, setCompData] = useState({})
    const handleDataFromChild = (childData, setOpenModal) => {
        setCompData(childData);
        console.log('Data received from child:', childData);
    };
    const [levelData, setLevelData] = useState({
        level: "",
        targetedHealth: "",
        targetedWealth: "",
        categoryName: "",
    })


    const RewardType = [
        { level: "Vouchers", value: "1" },
        { level: "In App Rewards", value: "2" },
        { level: "Audit Token", value: "3" },
        { level: "Premium Rewards", value: "4" },
        { level: "Trip Rewards", value: "5" }
    ]
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const ref = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (item, formikBag) => {
        getModalValue(item.value);
        formikBag.setFieldValue("categoryName", item.value);
        setCategoryName(item.value);
        setSelectedOption(item.level);
        setIsOpen(false);
    };

    const handleOutsideClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);
    const getData = async () => {
        try {
            const data = await axios.get(`/private/getLevelById/${param.id}`);
            setLevelData(data?.data?.data)
            setRewardType(data?.data?.data?.categoryName)
            setCategoryName(data?.data?.data?.categoryName)
            setCompData(data?.data?.data?.Reward)
            setSelectedOption(data?.data?.data?.categoryName)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getData()
    }, [])

    const getModalValue = (value) => {
        setRewardType(value)
        setOpenModal(true)
    }
    const handleAddLevel = async (value) => {
        setIsLoading(true);
        try {
            let formData = {
                level: value.level,
                targetedHealth: value?.targetedHealth,
                targetedWealth: value?.targetedWealth,
                categoryName: value?.categoryName,
                Reward: { ...compData }
            }
            const data = await axios.post(`/private/editlevel/${param.id}`, formData);
            toast.success(data?.data?.message);
            history.push("/level-management")
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.response.data?.errors[0].msg)
        }
    }

    const deleteReward = async (id) => {
        try {
            const data = await axios.delete(`/private/deleteLevelReward/${param.id}`);
            getData()
            toast.success(data?.data?.message);
        } catch (error) {
            toast.error(error.response.data?.errors[0].msg)

        }
    }
    return (
        <>
            <div className='hewe-cont addlevel'>
                <div className="flex-align-center">
                    <IoChevronBack
                        size={32}
                        className="back-color"
                        onClick={() =>
                            history.goBack()
                        }
                    />
                    <DashHeading>Edit Level</DashHeading>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={levelData}
                    validate={addLevelValidation}
                    validateOnChange
                    onSubmit={(values) => handleAddLevel(values)}
                >
                    {(formikBag) => {
                        return (
                            <Form className="p-3">
                                <div className="bg-white p-4">
                                    <div className="row">
                                        <div className="col-md-4" style={{ padding: "1rem" }}>
                                            <label>Level</label>
                                            <div className="form-controlcont">
                                                <Field name="level w-100">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="text"
                                                            value={formikBag.values.level}
                                                            onChange={(e) => {
                                                                formikBag.setFieldValue(
                                                                    "level",
                                                                    e.target.value
                                                                );
                                                            }}
                                                            // readOnly={true}
                                                            className="form-control"
                                                            placeholder="Enter Level"
                                                            minLength="1"
                                                            maxlength="3"
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <p className='error'>{
                                                formikBag.touched.level &&
                                                    formikBag.errors.level
                                                    ? formikBag.errors.level
                                                    : null
                                            }</p>
                                        </div>
                                        <div className="col-md-4" style={{ padding: "1rem" }}>
                                            <label>Health Goal</label>
                                            <div className="form-controlcont">
                                                <Field name="targetedWealth w-100">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="text"
                                                            value={formikBag.values.targetedHealth}
                                                            onChange={(e) => {
                                                                formikBag.setFieldValue(
                                                                    "targetedHealth",
                                                                    e.target.value
                                                                );
                                                            }}

                                                            className="form-control"
                                                            placeholder="Enter Health Goal (in Miles)"
                                                            maxlength="12"
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <p className='error'>
                                                {formikBag.touched.targetedHealth &&
                                                    formikBag.errors.targetedHealth
                                                    ? formikBag.errors.targetedHealth
                                                    : null
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4" style={{ padding: "1rem" }}>
                                            <label>Wealth Goal</label>
                                            <div className="form-controlcont">
                                                <Field name="targetedHealth w-100">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="text"
                                                            value={formikBag.values.targetedWealth}
                                                            onChange={(e) => {
                                                                formikBag.setFieldValue(
                                                                    "targetedWealth",
                                                                    e.target.value
                                                                );
                                                            }}

                                                            className="form-control"
                                                            placeholder="Enter Wealth Goal (in Hewe coins)"
                                                            maxlength="12"
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <p className='error'>{
                                                formikBag.touched.targetedWealth &&
                                                    formikBag.errors.targetedWealth
                                                    ? formikBag.errors.targetedWealth
                                                    : null
                                            }</p>
                                        </div>
                                        <div className="col-md-4" style={{ padding: "1rem" }}>
                                            <label>Reward Type</label>
                                            <div className="form-controlcont" ref={ref}>
                                                <div className="redwardTypeselect position-relative" >
                                                    <div className='d-flex justify-content-between align-items-center' onClick={toggleDropdown}>
                                                        <div
                                                            className="select-button  pointer"
                                                        >
                                                            {selectedOption === "1"
                                                                ? "Voucher"
                                                                : RewardType.find((type) => type.value === selectedOption)?.level || "Select Type"}
                                                        </div>
                                                        <FaCaretDown className='text-black' />
                                                    </div>
                                                    {isOpen && selectedOption == undefined && (
                                                        <div className="options-container">
                                                            {RewardType?.map((item) => (
                                                                <div key={item.value} className="option-button" onClick={() => handleOptionClick(item, formikBag)}>
                                                                    {item.level}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>


                                            <p className='error'>{
                                                formikBag.touched.categoryName &&
                                                    formikBag.errors.categoryName
                                                    ? formikBag.errors.categoryName
                                                    : null
                                            }</p>
                                        </div>
                                    </div>
                                    {compData?.rewardIcon &&
                                        <div className='levelHistiry mt-5'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-flex align-items-center'>
                                                    <h3 className='mb-0 me-3'>Reward description</h3> <div><div className='editbtn' onClick={() => setOpenModal(true)}><MdModeEdit /> Edit </div></div>
                                                </div>
                                                <div>
                                                    <div className='deletebtn' onClick={() => deleteReward(levelData._id)}><Delete /> Delete</div>
                                                </div>
                                            </div>

                                            <div className="levelHistorycont py-5 mt-4 d-flex align-items-center px-5">
                                                <div className="imgcont">
                                                    <img src={`data:image/png;base64,${compData?.rewardIcon}`} alt="img" />
                                                </div>
                                                <div className='mx-3'>
                                                    <label htmlFor="">Reward Name</label>
                                                    <div className='inpt'>{compData?.rewardName}</div>
                                                </div>
                                                {
                                                    compData?.voucherCode && <>
                                                        <div className='mx-3'>
                                                            <label htmlFor="">Voucher Code</label>
                                                            <div className='inpt'>{compData?.voucherCode}</div>
                                                        </div>
                                                    </>
                                                }

                                                {
                                                    compData?.rewardExpireDate && <>
                                                        <div className='mx-3'>
                                                            <label htmlFor="">Reward Expiry Date</label>
                                                            <div className="inpt">
                                                                {compData?.rewardExpireDate
                                                                    ? new Date(compData.rewardExpireDate).toLocaleDateString("en-IN")
                                                                    : "No date available"}
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className='mx-3'>
                                                    <label htmlFor="">Quantity</label>
                                                    <div className='inpt'>{compData?.rewardAmount}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="d-flex justify-content-center mt-5" style={{ padding: "1rem" }}>
                                        <button
                                            type="submit"
                                            className="btn1"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>


            </div>
            <Modal
                maxWidth="lg"
                width="100%"
                RoundedCorners={true}
                isOpen={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                backgroundModal={false}
                backgroundModalContent={false}
                title={
                    <div className="p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <p className="ps-2 main-heading">
                                    Edit{" "}
                                    {categoryName === "1"
                                        ? "Voucher"
                                        : categoryName === "2"
                                            ? "In App Reward"
                                            : categoryName === "3"
                                                ? "Audit Reward"
                                                : categoryName === "4"
                                                    ? "Premium Reward"
                                                    : categoryName === "5"
                                                        ? "Trip Reward"
                                                        : ""}
                                </p>
                            </div>
                            <MdOutlineClose
                                style={{
                                    fontWeight: 600,
                                    fontSize: "22px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setOpenModal(false);
                                }}
                            />
                        </div>

                    </div>
                }
                content={
                    <>
                        {
                            rewardType == "1" ? <EditLevelVoucher rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "2" ? <EditLevelInAppReward rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "3" ? <EditLevelAuditToken rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "4" ? <EditLevelPremium rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "5" ? <EditLevelTrip rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : null
                        }
                    </>
                }
            />


            {isLoading && <Overlay />}

        </>

    )
}
